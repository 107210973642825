import React, { FC, useState, useEffect } from 'react';
import { Grid, Typography, TextField, MenuItem, InputAdornment, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/core/styles';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
// Fetch, Types, Helpers
import { getClientAppLookup, getExtranetProjects, getClientLookup, createRelease, getReleaseById, updateRelease } from '@shared/fetch';
import { IClientAppLookup, IClientLookup, IUserProject, IReleaseRequest, IRelease, ReleaseTimeZone } from '@shared/types';
import { calculateReleaseWithTime, releaseLengths, getTimezones, getReleaseTimeZoneName } from '@shared/helpers';
// Context
import { useValidation } from '@src/context/validation-context';
// Components
import { ActionButtons } from '../action-buttons';
import { DashboardCard } from '../../../clients/components/DashboardCard';
import { DatePicker } from '@shared/components/inputs';
import { Toast } from '@shared/components/toast';
import { useParams } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';

interface GeneralReleaseFormProps {
  setClientId: (clientId: number | null) => void;
  setReleaseId: (releaseId: number) => void;
  setReleaseDate: (date: Date | null) => void;
  setSelectedReleaseTime: (time: string) => void;
  setTimeZone: (zone: number | string) => void;  
}

export const GeneralReleaseForm: FC<GeneralReleaseFormProps> = ({ setClientId, setReleaseId, setReleaseDate, setSelectedReleaseTime, setTimeZone }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ release: IRelease }>();
  const theme = useTheme();
  const { releaseId } = useParams<{ releaseId: string }>();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const customPaddingTitle = isMediumDown ? { padding: '1.5rem 1rem 0.5rem 1rem' } : { padding: '1rem 1rem 0.5rem 1rem' };
  const timezones = getTimezones(ReleaseTimeZone);
  const { setPageKey, setValidationMessages } = useValidation();
  // States //
  const [relatedClient, setRelatedClient] = useState<number | string>('');
  const [clientList, setClientList] = useState<IClientLookup[]>([]);
  const [applicationList, setApplicationList] = useState<IClientAppLookup[]>([]);
  const [projectList, setProjectList] = useState<IUserProject[]>([]);
  const [clientProjectBudget, setClientProjectBudget] = useState<number | string>('');
  const [selectedApplication, setSelectedApplication] = useState<number | string>('');
  const [releaseTitle, setReleaseTitle] = useState('');

  const [releaseDate, setReleaseDateStateLocal] = useState<Date | null>(null);
  const [selectedReleaseTime, setSelectedReleaseTimeLocal] = useState('');
  const [timeZoneLocal, setTimeZoneLocal] = useState<number | string>('');
  
  const [projectedReleaseLength, setProjectedReleaseLength] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [reason, setReason] = useState('');
  const [infrastructureTeam, setInfrastructureTeam] = useState('sbell@mercuryworks.com');

  const StyledHttpsOutlinedIcon = styled(HttpsOutlinedIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontSize: '1.25rem'
  }));

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  useEffect(() => {
    setPageKey('generalPage');
  }, [setPageKey]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientResult = await getClientLookup(true); // Pass true for isActive
        setClientList(clientResult);
        // Map the client name to the client ID
        if (location.state?.release.clientName) {
          const client = clientResult.find(client => client.name === location.state.release.clientName);
          if (client) {
            setRelatedClient(client.clientId.toString());
            setClientId(client.clientId);
          }
        }
      } catch (error) {
        console.error('Error fetching client lookup data:', error);
        setPageToast({
          message: 'Error fetching clients',
          variant: 'error',
          isOpen: true
        });
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const fetchProjectsAndApplications = async () => {
      if (relatedClient) {
        try {
          const [projectsResult, applicationResult] = await Promise.all([
            getExtranetProjects(Number(relatedClient), false, true),
            getClientAppLookup(Number(relatedClient))
          ]);
          setProjectList(projectsResult);
          setApplicationList(applicationResult);
          // Map the application name to the application ID
          if (location.state?.release.application) {
            const application = applicationResult.find(app => app.description === location.state.release.application);
            if (application) {
              setSelectedApplication(application.value.toString());
            }
          }
        } catch (error) {
          console.error('Error fetching projects or applications data:', error);
          setPageToast({
            message: 'Error fetching applications data',
            variant: 'error',
            isOpen: true
          });
        }
      }
    };

    fetchProjectsAndApplications();
  }, [relatedClient]);

  const handleTimeZoneChange = (value: string | number) => {
    setTimeZoneLocal(value);
    setTimeZone(value);  // Update the parent component's timeZone state
  };

  useEffect(() => {
    if (releaseId !== 'add') {
      const fetchReleaseData = async () => {
        try {
          const releaseData = await getReleaseById(Number(releaseId));
          if (releaseData.releaseDate) {
            const releaseDate = new Date(releaseData.releaseDate);
            const releaseEndDate = new Date(releaseData.releaseEndDate);
            const releaseLengthInMinutes = differenceInMinutes(releaseEndDate, releaseDate);
            const hours = Math.floor(releaseLengthInMinutes / 60);
            const minutes = releaseLengthInMinutes % 60;
            const formattedReleaseLength = `${hours}:${minutes.toString().padStart(2, '0')}`;
            setReleaseDateStateLocal(releaseDate);
            setSelectedReleaseTimeLocal(releaseDate.toTimeString().substring(0, 5));
            setReleaseDate(releaseDate);
            setSelectedReleaseTime(releaseDate.toTimeString().substring(0, 5)); 
            setTimeZoneLocal(releaseData.releaseTimeZone ?? '');
            setTimeZone(releaseData.releaseTimeZone ?? ''); 
            setProjectedReleaseLength(formattedReleaseLength ?? '');
          }

          setRelatedClient(releaseData?.clientId ?? '');
          setSelectedApplication(releaseData?.applicationId ?? '');
          setClientProjectBudget(releaseData?.clientProjectId ?? '');
          setReleaseTitle(releaseData?.releaseSummary ?? '');
          setReason(releaseData?.reason ?? '');
        } catch (error) {
          console.error('Error fetching release data:', error);
        }
      };
      fetchReleaseData();
    }
  }, [releaseId, projectList]);

  useEffect(() => {
    const errors: string[] = [];
    // Field-Level Validation
    if (!relatedClient) {
      errors.push('Client');
    }
    if (!selectedApplication) {
      errors.push('Application');
    }
    if (!releaseTitle) {
      errors.push('Release Summary');
    }
    if (!clientProjectBudget) {
      errors.push('Client Budget');
    }
    if (!releaseDate) {
      errors.push('Release Date');
    }
    if (!selectedReleaseTime) {
      errors.push('Release Time');
    }
    setValidationMessages(prev => {
      const filteredMessages = prev.filter(
        msg =>
          msg !== 'Client' &&
          msg !== 'Application' &&
          msg !== 'Release Summary' &&
          msg !== 'Client Budget' &&
          msg !== 'Release Date' &&
          msg !== 'Release Time'
      );
      return [...filteredMessages, ...errors];
    });
  }, [relatedClient, selectedApplication, releaseTitle, clientProjectBudget, releaseDate, selectedReleaseTime, setValidationMessages]);

  const handleCancel = () => {
    history.push('/employees/software-releases');
  };

  const handleSave = async () => {
    if (!validateForm()) return;
    if (!releaseDate) {
      setSelectedReleaseTime('');
      setSelectedReleaseTimeLocal('');
      setTimeZone('');
      handleTimeZoneChange('');
    }
    // Set the parent states using the values from internal states
    setReleaseDate(releaseDate);
    setSelectedReleaseTime(selectedReleaseTime);
    // Set default time zone to 'EST' if not selected by the user
    const selectedTimeZone = timeZoneLocal ? String(timeZoneLocal) : 'EST';
    let releaseDateAndTime = calculateReleaseWithTime(
      releaseDate,
      selectedReleaseTime ? selectedReleaseTime : '00:00',
      getReleaseTimeZoneName(selectedTimeZone)
    );
    const releaseData: IReleaseRequest = {
      clientId: Number(relatedClient),
      releaseSummary: releaseTitle,
      releaseDate: releaseDateAndTime ? releaseDateAndTime.toISOString(true) : '',
      applicationId: Number(selectedApplication),
      reason: reason,
      clientProjectId: Number(clientProjectBudget),
      releaseTimeZone: selectedTimeZone
    };

    try {
      if (releaseId !== 'add') {
        await updateRelease(Number(releaseId), releaseData);
        setPageToast({
          message: 'Release updated successfully',
          variant: 'success',
          isOpen: true
        });
      } else {
        const response = await createRelease(releaseData);
        if (typeof response === 'number') {
          setReleaseId(response); // Set releaseId from response if it's a number
          setClientId(Number(relatedClient));
          history.push(`/employees/software-releases/${response}/general-information`);
        } else {
          console.warn('Release ID is not available in the response');
        }
        setPageToast({
          message: 'Release saved successfully',
          variant: 'success',
          isOpen: true
        });
      }
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving release:', error);
      setPageToast({
        message: (error as any)?.Detail ?? 'An error occurred while saving the release',
        variant: 'error',
        isOpen: true
      });
    }
  };

  const handleSaveAndClose = async () => {
    await handleSave();
    history.push('/employees/software-releases');
  };

  const validateForm = () => {
    return relatedClient && selectedApplication && releaseTitle && clientProjectBudget;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ActionButtons onCancel={handleCancel} onSave={handleSave} onSaveAndClose={handleSaveAndClose} disableSave={!validateForm()} />
        </Grid>
      </Grid>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item xs={12}>
          <DashboardCard setHeight={false} isColumn={false} hideTitle={true} elevation={6}>
            <Grid spacing={2} container>
              <Grid item xs={12} style={customPaddingTitle}>
                <Typography className={classes.title}>General Release Info</Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formItems}>
                <TextField
                  required
                  fullWidth
                  label='Client'
                  variant='outlined'
                  select
                  size='small'
                  disabled={isSaved || releaseId !== 'add'}
                  value={relatedClient}
                  onChange={e => setRelatedClient(e.target.value)}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                          overflowY: 'auto'
                        }
                      }
                    }
                  }}
                >
                  {clientList.map(client => (
                    <MenuItem key={client.clientId} value={client.clientId}>
                      {client.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formItems}>
                <TextField
                  required
                  fullWidth
                  label='Application'
                  variant='outlined'
                  select
                  size='small'
                  value={selectedApplication}
                  onChange={e => {
                    setSelectedApplication(e.target.value);
                  }}
                  disabled={!relatedClient}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                          overflowY: 'auto'
                        }
                      }
                    }
                  }}
                >
                  {applicationList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formItems}>
                <TextField
                  required
                  size='small'
                  fullWidth
                  label='Release Summary'
                  variant='outlined'
                  value={releaseTitle}
                  onChange={e => {
                    setReleaseTitle(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formItems}>
                <TextField
                  required
                  size='small'
                  fullWidth
                  label='Client Project Budget'
                  variant='outlined'
                  select
                  value={clientProjectBudget}
                  onChange={e => {
                    setClientProjectBudget(e.target.value);
                  }}
                  disabled={!relatedClient}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto'
                        }
                      }
                    }
                  }}
                >
                  {projectList.map(project => (
                    <MenuItem key={project.clientProjectId} value={project.clientProjectId}>
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} className={classes.formItems}>
                <TextField
                  fullWidth
                  size='small'
                  label='Reason'
                  variant='outlined'
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12} className={classes.formItems}>
                <TextField
                  fullWidth
                  size='small'
                  label='Infrastructure Team'
                  variant='outlined'
                  value={infrastructureTeam}
                  onChange={e => setInfrastructureTeam(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <StyledHttpsOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className={classes.formItems}>
                <DatePicker
                  id='releaseDate'
                  label={isMediumDown ? 'Release Date' : 'Selected Release Date'}
                  value={releaseDate}
                  onChange={setReleaseDateStateLocal}
                  inputVariant='outlined'
                  disableMargin
                  size='small'
                  className={classes.datePickerWidth}
                />
              </Grid>
              <Grid item xs={12} sm={3} className={classes.formItems}>
                <TextField
                  fullWidth
                  size='small'
                  label='Selected Release Time'
                  variant='outlined'
                  type='time'
                  value={selectedReleaseTime}
                  onChange={e => setSelectedReleaseTimeLocal(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.formItems}>
                <TextField
                  fullWidth
                  size='small'
                  label='Time Zone'
                  variant='outlined'
                  select
                  value={timeZoneLocal}
                  onChange={e => handleTimeZoneChange(e.target.value)}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 125,
                          overflowY: 'auto'
                        }
                      }
                    }
                  }}
                >
                  {timezones.map(tz => (
                    <MenuItem key={tz.id} value={tz.value}>
                      {tz.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.formItems}>
                <TextField
                  fullWidth
                  size='small'
                  label='Projected Release Length'
                  variant='outlined'
                  select
                  value={projectedReleaseLength}
                  onChange={e => setProjectedReleaseLength(e.target.value)}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 150,
                          overflowY: 'auto'
                        }
                      }
                    }
                  }}
                >
                  {releaseLengths.map(length => (
                    <MenuItem key={length.value} value={length.value}>
                      {length.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DashboardCard>
        </Grid>
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.10rem',
    color: '#616161',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  datePickerWidth: {
    width: '100%'
  },
  formItems: {
    '&.MuiGrid-item': {
      padding: '0.75rem 1rem'
    }
  }
}));
