import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { DatePicker } from '@shared/components/inputs';

interface IDateTimeInputProps {
  label?: string;
  dateLabel?: string;
  timeLabel?: string;
  date: Date | null;
  time: string;
  timeZone: string;
  onDateChange: (date: Date | null) => void;
  onTimeChange: (time: string) => void;
  onTimeZoneChange: (zone: string) => void;
  timezones: { id: number; value: string; label: string }[];
  className?: string;
  fixedLabelWidth?: string;
}

export const DateTimeInput: FC<IDateTimeInputProps> = ({
  label = '',
  date,
  time,
  timeZone,
  onDateChange,
  onTimeChange,
  onTimeZoneChange,
  timezones,
  className,
  fixedLabelWidth,
  dateLabel = 'Date',
  timeLabel = 'Time'
}) => {
  const classes = useStyles({ fixedLabelWidth });
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container spacing={1} alignItems='center' className={className}>
      <Grid item>
        <Typography className={clsx('boldLabel', classes.fixedLabel)}>{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <DatePicker
          id={`${label}-date`}
          label={isMediumDown ? 'Date' : dateLabel}
          value={date}
          onChange={onDateChange}
          inputVariant='outlined'
          disableMargin
          size='small'
          className={classes.datePickerWidth}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          size='small'
          label={timeLabel}
          variant='outlined'
          type='time'
          value={time}
          onChange={e => onTimeChange(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          size='small'
          label='Time Zone'
          variant='outlined'
          select
          value={timeZone}
          onChange={e => onTimeZoneChange(e.target.value)}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  maxHeight: 125,
                  overflowY: 'auto'
                }
              }
            }
          }}
        >
          {timezones.map(tz => (
            <MenuItem key={tz.id} value={tz.value}>
              {tz.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  datePickerWidth: {
    width: '100%'
  },
  fixedLabel: {
    width: ({ fixedLabelWidth }: { fixedLabelWidth?: string }) => fixedLabelWidth || '5rem',
    display: 'inline-block',
    marginLeft: '.5rem'
  }
}));
