import React, { FC } from 'react';
import { DialogActions, Button, Typography } from '@material-ui/core';
import { Modal } from '@shared/components/modals/Modal';
import { Loader } from '@shared/components/loader';
import { getClient } from '@shared/fetch';
import { ApprovalType } from '@shared/types';
import { useQuery } from 'react-query';

interface IReleaseApprovalModal {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  isMediumDown?: boolean;
  clientId: number | null;
  approvalType: ApprovalType;
  handleDeliveryLeadApproveSendEmail: () => void;
  handleClientApprovedSendEmail: () => void;
}

export const ReleaseApprovalModal: FC<IReleaseApprovalModal> = ({
  isModalOpen,
  handleCloseModal,
  isMediumDown,
  clientId,
  approvalType,
  handleDeliveryLeadApproveSendEmail,
  handleClientApprovedSendEmail
}) => {
  const { isLoading: isLoadingClientData, data: clientData } = useQuery(
    ['client', clientId],

    async () => await getClient(Number(clientId) ?? null),
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!clientId
    }
  );

  const setTitle = () => {
    if (approvalType === ApprovalType.DELIVERYLEADAPPROVED) {
      return 'Send Client Approval Email';
    } else if (approvalType === ApprovalType.CLIENTAPPROVED) {
      return 'Approve Release';
    }
  };

  return (
    <Modal
      maxWidth='md'
      open={isModalOpen}
      onClose={handleCloseModal}
      title={setTitle()}
      customPaddingContent={isMediumDown ? '' : '0 1.5rem 1.5rem 1.5rem'}
    >
      {isLoadingClientData ? (
        <Loader position='centered' type='overlay' size='small' />
      ) : (
        <>
          {approvalType === ApprovalType.DELIVERYLEADAPPROVED && (
            <>
              <Typography>Do you want to send an email to {clientData?.name ?? 'the client'} to approve the release?</Typography>
              <DialogActions className='mt-2'>
                <Button onClick={handleCloseModal} color='primary'>
                  Close
                </Button>
                <Button onClick={handleDeliveryLeadApproveSendEmail} color='primary' variant='contained'>
                  Send
                </Button>
              </DialogActions>
            </>
          )}
          {approvalType === ApprovalType.CLIENTAPPROVED && (
            <>
              <Typography>Are you ready to send meetings and updates for the approved release?</Typography>
              <DialogActions className='mt-2'>
                <Button onClick={handleCloseModal} color='primary'>
                  Close
                </Button>
                <Button onClick={handleClientApprovedSendEmail} color='primary' variant='contained'>
                  Send
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Modal>
  );
};
