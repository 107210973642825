import React, { FC, useState, useEffect } from 'react';
import { Grid, Typography, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SaveOutlined, Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
// Types
import { ReleaseTimeZone } from '@shared/types';
import { getTimezones } from '@shared/helpers';
// Fetch
import { useQuery } from 'react-query';
import {
  updateExecutionSteps,
  getExecutionSteps,
  updateExecutionStepStatus,
  updateReleaseDates,
  getReleaseById,
  createExecutionStep,
  updateExecutionStep
} from '@shared/fetch';
// Components
import { DateTimeInput } from '@shared/components/forms';
import { Loader } from '@shared/components/loader';
import { Alert } from '@shared/components/alerts';
import { ConfirmationModal } from '../../../employees/components/modals';
import { DashboardCard } from '../../../clients/components/DashboardCard';
import { DragAndDropCheckbox } from '@shared/components/drag-and-drop';
import { Toast } from '@shared/components/toast/Toast';

interface IReleasing {
  releaseId: number | null;
  initialReleaseDate: Date | null;
  initialSelectedReleaseTime: string;
  initialTimeZone: number | string;
  onReleaseDateChange: (date: Date | null) => void;
  onSelectedReleaseTimeChange: (time: string) => void;
  onTimeZoneChange: (zone: number | string) => void;
  onPendingChangesChange: (hasPendingChanges: boolean) => void;
}

export const Releasing: FC<IReleasing> = ({
  releaseId,
  initialReleaseDate,
  initialSelectedReleaseTime,
  initialTimeZone,
  onReleaseDateChange,
  onSelectedReleaseTimeChange,
  onTimeZoneChange,
  onPendingChangesChange
}) => {
  const classes = useStyles();
  const timezones = getTimezones(ReleaseTimeZone);
  const history = useHistory();
  // States
  const [editingId, setEditingId] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [hasPendingChangesDate, setHasPendingChangesDate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<string | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [releaseDate, setReleaseDate] = useState<Date | null>(initialReleaseDate);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedReleaseTime, setSelectedReleaseTime] = useState(initialSelectedReleaseTime || '00:00');
  const [selectedEndTime, setSelectedEndTime] = useState('');
  const [timeZone, setTimeZone] = useState(initialTimeZone);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  // Modal reminding to save before navigate //

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    setDialogOpen(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (hasPendingChangesDate && !confirmedNavigation) {
        setNextLocation(location.pathname);
        setDialogOpen(true);
        return false; // Prevent navigation
      }
      return undefined;
    });

    return () => {
      unblock();
    };
  }, [hasPendingChangesDate, confirmedNavigation, history]);

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      setConfirmedNavigation(false); // Reset the state
      history.push(nextLocation);
    }
  }, [confirmedNavigation, nextLocation, history]);

  // end Modal logic //

  useEffect(() => {
    setReleaseDate(initialReleaseDate);
    setSelectedReleaseTime(initialSelectedReleaseTime);
    setTimeZone(initialTimeZone);
  }, [initialReleaseDate, initialSelectedReleaseTime, initialTimeZone]);

  const {
    isLoading: isLoadingExecutionSteps,
    data: executionSteps = [],
    refetch: refetchExecutionSteps,
    isRefetching: isRefetchingExecutionSteps
  } = useQuery<any[], Error>(
    ['executionPlans', releaseId],
    async () => {
      const steps = await getExecutionSteps(releaseId, true);
      const orderedReleaseExecutionSteps = steps.map((step: any, index: number) => ({
        ...step,
        order: index + 1
      }));
      return orderedReleaseExecutionSteps;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!releaseId
    }
  );

  useEffect(() => {
    const fetchReleaseContents = async () => {
      try {
        const releaseDataResult = await getReleaseById(Number(releaseId));
        if (releaseDataResult) {
          const releaseEndDate = releaseDataResult.releaseEndDate ? new Date(releaseDataResult.releaseEndDate) : null;
          setEndDate(releaseEndDate);
          const extractedEndTime = releaseEndDate ? moment(releaseEndDate).format('HH:mm') : '';
          setSelectedEndTime(extractedEndTime);

          const timeZoneOffset = releaseEndDate ? moment(releaseEndDate).format('Z') : null;
          const convertedTimeZone = timeZoneOffset ? moment.tz.zone(timeZoneOffset)?.name : '';
          setTimeZone(convertedTimeZone || initialTimeZone);
        }
      } catch (error) {
        console.log('Error fetching release contents:', error);
      }
    };
    fetchReleaseContents();
  }, [releaseId, initialTimeZone]);

  const handleDateChange = (setter: (date: Date | null) => void, date: Date | null) => {
    setter(date);
    setHasPendingChangesDate(true);
    onPendingChangesChange(true);
  };

  const handleTimeChange = (setter: (time: string) => void, time: string) => {
    setter(time);
    setHasPendingChangesDate(true);
    onPendingChangesChange(true);
  };

  const handleTimeZoneChange = (zone: string | number) => {
    setTimeZone(zone);
    setHasPendingChangesDate(true);
    onPendingChangesChange(true);
  };

  const handleSaveDate = async () => {
    if (!releaseDate || !endDate) {
      setAlertText('Both start and end dates must be set.');
      setAlertOpen(true);
      return;
    }
    const startTime = selectedReleaseTime || initialSelectedReleaseTime || '00:00';
    const endTime = selectedEndTime || '00:00';

    const startDateTime = moment(`${releaseDate.toISOString().split('T')[0]}T${startTime}`).format();
    const endDateTime = moment(`${endDate.toISOString().split('T')[0]}T${endTime}`).format();

    const releaseDates = {
      startDate: startDateTime,
      endDate: endDateTime,
      timeZone
    };
    if (!moment(startDateTime).isBefore(moment(endDateTime))) {
      setAlertText('The start date must be before the end date.');
      setAlertOpen(true);
      return;
    }
    try {
      await updateReleaseDates(releaseId as number, releaseDates);
      onReleaseDateChange(releaseDate);
      onSelectedReleaseTimeChange(startTime);
      onTimeZoneChange(timeZone);
      setHasPendingChangesDate(false);
      onPendingChangesChange(false);
      setPageToast({
        message: 'Release dates saved!',
        variant: 'success',
        isOpen: true
      });
    } catch (error) {
      console.log('Error updating release dates', error);
    }
  };

  // Start steps create, updates here //

  const updateStepsState = (updatedSteps: any[]) => {
    setEditingId(null); // Close the editor
  };

  const handleCreate = async (name: string, id: string | number) => {
    try {
      setIsUpdating(true);
      const createdStep = await createExecutionStep(releaseId, { name, order: executionSteps.length + 1, isReleasingStageStep: true });
      const updatedSteps = [...executionSteps, createdStep].map((step: any, index: number) => ({
        ...step,
        releaseExecutionStepId: step.id,
        order: index + 1 
      }));

      updateStepsState(updatedSteps); // Update local state
      setEditingId(createdStep.id.toString()); // Open editor on the new step
      await updateExecutionSteps(releaseId, { updates: updatedSteps });
      await refetchExecutionSteps();
    } catch (error) {
      console.error('Error creating execution step:', error);
      setAlertText('Error adding step');
      setAlertOpen(true);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateExecutionSteps = async (newItems: any[]) => {
    const orderedReleaseExecutionSteps = newItems.map((step: any, index: number) => ({
      ...step,
      releaseExecutionStepId: step.id,
      order: index + 1
    }));
    try {
      setIsUpdating(true); 
      updateStepsState(orderedReleaseExecutionSteps); // Update local state
      await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });
      await refetchExecutionSteps();
    } catch (error) {
      console.error('Error updating steps:', error);
      setPageToast({
        message: 'Error updating verification steps',
        variant: 'error',
        isOpen: true,
      });
    } finally {
      setIsUpdating(false); 
    }
  };

  const handleCheckboxChange = async (id: number, checked: boolean) => {
    try {
      const status = checked ? 'Done' : 'NotDone';
      await updateExecutionStepStatus(releaseId, id.toString(), status);
      await refetchExecutionSteps();
    } catch (error) {
      console.error('Error updating step:', error);
      setPageToast({
        message: 'Failed to update step',
        variant: 'error',
        isOpen: true,
      });
    }
  };

  const handleUpdate = async (payload: any, id: string | number) => {
    try {
      setIsUpdating(true);
      const updatedStep = await updateExecutionStep(releaseId, id, payload);
        const updatedSteps = (executionSteps || []).map((step: any, index: number) =>
        step.id === updatedStep.id ? { ...updatedStep, order: index + 1 } : { ...step, order: index + 1 }
      );

      const orderedReleaseExecutionSteps = updatedSteps.map((step: any, index: number) => ({
        ...step,
        releaseExecutionStepId: step.id,
        order: index + 1,
      }));
  
      updateStepsState(updatedSteps);
      await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });
      await refetchExecutionSteps();
    } catch (error) {
      console.error('Error updating step:', error);
      setAlertText('Error updating step');
      setAlertOpen(true);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
      <Alert open={alertOpen} onClose={setAlertOpen} type='error' text={alertText} />
      <Grid item xs={12}>
        <DashboardCard setHeight={false} isColumn={false} hideTitle={true}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.title}>Release Steps</Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoadingExecutionSteps ? (
                <Loader position='centered' />
              ) : (
                <>
                  {executionSteps && executionSteps.length > 0 ? (
                    <DragAndDropCheckbox
                      items={executionSteps}
                      onItemsReorder={handleUpdateExecutionSteps}
                      onCheckboxChange={handleCheckboxChange}
                      onUpdate={handleUpdate}
                      editingId={editingId}
                      setEditingId={setEditingId}
                    />
                  ) : (
                    <Typography variant='body1'>No active release steps.</Typography>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: 10 }}>
              <Button
                className={classes.addButton}
                startIcon={<Add />}
                onClick={() => handleCreate('', `new-${new Date().getTime()}`)}
                disabled={isLoadingExecutionSteps || isRefetchingExecutionSteps || isUpdating || editingId !== null}
              >
                Add Task
              </Button>
            </Grid>
          </Grid>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} className='mb-6'>
        <DashboardCard setHeight={false} isColumn={false} hideTitle={true}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={12}>
              <Typography className={classes.title}>Release</Typography>
            </Grid>
            {/* Start Date/Time Input */}
            <DateTimeInput
              label='Started at:'
              dateLabel='Selected Release Date'
              date={releaseDate}
              time={selectedReleaseTime}
              timeZone={String(timeZone)}
              onDateChange={date => handleDateChange(setReleaseDate, date)}
              onTimeChange={time => handleTimeChange(setSelectedReleaseTime, time)}
              onTimeZoneChange={handleTimeZoneChange}
              timezones={timezones}
              className='mt-1'
            />
            {/* End Date/Time Input */}
            <DateTimeInput
              label='Ended at:'
              timeLabel='Selected End Time'
              date={endDate}
              time={selectedEndTime}
              timeZone={String(timeZone)}
              onDateChange={date => handleDateChange(setEndDate, date)}
              onTimeChange={time => handleTimeChange(setSelectedEndTime, time)}
              onTimeZoneChange={handleTimeZoneChange}
              timezones={timezones}
              className='mt-2'
            />
            <Grid item xs={12}>
              <Divider className='customHr' />
            </Grid>
            <Grid container justify='flex-end'>
              <Button startIcon={<SaveOutlined />} onClick={handleSaveDate} disabled={!hasPendingChangesDate} className={classes.saveButton}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DashboardCard>
        <ConfirmationModal
          open={dialogOpen}
          onConfirm={handleDialogConfirm}
          onCancel={handleDialogClose}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.125rem',
    color: '#616161',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: '0.75rem 0 0.5rem 0'
    }
  },
  cardHolder: {
    alignItems: 'stretch',
    marginBottom: '0.5rem'
  },
  saveButton: {
    color: theme.palette.success.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }
  },
  datePickerWidth: {
    width: '100%'
  },
  fixedLabelWidth: {
    width: '5rem',
    display: 'inline-block'
  },
  addButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 500,
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.25)
    }
  }
}));
