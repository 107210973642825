import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { theme } from '@shared/helpers';
import { canExitSoftwareRelease, exitSoftwareRelease, approveSoftwareRelease, getReleaseById } from '@shared/fetch';
import { ApprovalType } from '@shared/types';
import { useQuery } from 'react-query';
// Context
import { ValidationProvider } from '@src/context/validation-context';
// Components
import { ProgressStepper } from '@shared/components/steppers';
import { Toast } from '@shared/components/toast/Toast';
import { DashboardCard } from '../../clients/components/DashboardCard';
import { stepDefinition, SoftwareReleaseUrlSubdirectory } from './SoftwareReleaseDetail/types';
import { GeneralReleaseForm } from '../components/forms';
import { AssignedEmployees, ClientContacts, WaitingRelease, ReleaseContents } from '../components/layouts';
import { Releasing } from './Releasing/Releasing';
import { Verifying } from './Verifying/Verifying';
import ReleaseDetails from './ReleaseDetails/ReleaseDetails';
import { ReleaseApprovalModal } from '../components/modals/ReleaseApprovalModal';

const SoftwareReleaseDetail: FC = () => {
  const [activeStep, setActiveStep] = useState(1);
  const { releaseId: initialReleaseId } = useParams<{ releaseId: string }>();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  // States
  const [contactsAssigned, setContactsAssigned] = useState<boolean>(false);
  const [employeesAssigned, setEmployeesAssigned] = useState<boolean>(false);
  const [hasPendingChangesDate, setHasPendingChangesDate] = useState(false);

  const [releaseDate, setReleaseDate] = useState<Date | null>(null);
  const [selectedReleaseTime, setSelectedReleaseTime] = useState<string>('');
  const [timeZone, setTimeZone] = useState<number | string>('');

  const [clientId, setClientId] = useState<number | null>(null);
  const [approvalType, setApprovalType] = useState<ApprovalType>(ApprovalType.UNSET);
  const [releaseId, setReleaseId] = useState<number | null>(initialReleaseId && initialReleaseId !== 'add' ? Number(initialReleaseId) : null);
  const [releaseStatusName, setReleaseStatusName] = useState<string>('');

  useEffect(() => {
    // Find the index of the active step based on the current URL
    const activeStepIndex = stepDefinition.findIndex(step => {
      // Ensure the URL is defined before using it
      return step.url && location.pathname.includes(step.url);
    });
    // Set the active step if a valid step is found
    if (activeStepIndex !== -1) {
      setActiveStep(activeStepIndex);
    }
  }, [location.pathname, stepDefinition]);

  useEffect(() => {
    fetchReleaseStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  const [canDeliveryLeadSave, setCanDeliveryLeadSave] = useState(false);
  const { refetch: refetchCanDeliveryLeadSave } = useQuery(
    ['canDeliveryLeadSave', releaseId],
    async () => await canExitSoftwareRelease(initialReleaseId),
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!releaseId,
      onError: (error: any) => {
        console.log(`${error?.response.data.Detail ?? 'Error checking if release can be approved'}`);
        return setCanDeliveryLeadSave(false);
      },
      onSuccess: (data: any) => {
        setCanDeliveryLeadSave(data);
      }
    }
  );

  const fetchReleaseStatus = async () => {
    try {
      const releaseDataResult = await getReleaseById(Number(releaseId));
      setReleaseStatusName(releaseDataResult.releaseStatusName);
    } catch (error) {
      console.error('Error fetching release contents:', error);
    }
  };

  const handleDeliveryLeadApprove = async () => {
    try {
      setApprovalType(ApprovalType.DELIVERYLEADAPPROVED);
      handleOpenClientApproveEmailModal();
    } catch (error) {
      alert('error');
    }
  };

  const handleClientApproved = async () => {
    try {
      setApprovalType(ApprovalType.CLIENTAPPROVED);
      handleOpenClientApproveEmailModal();
    } catch (error) {
      alert('error');
    }
  };

  const handleClientApprovedSendEmail = async () => {
    try {
      await approveSoftwareRelease(initialReleaseId);
      history.push(`/employees/software-releases/${releaseId}/waiting-for-release`);
    } catch (error) {
      setPageToast({
        message: `${(error as any)?.response?.data?.Detail ?? 'Error approving release'}`,
        variant: 'error',
        isOpen: true
      });
    }
  };

  const handleDeliveryLeadApproveSendEmail = async () => {
    try {
      await exitSoftwareRelease(initialReleaseId);
      await fetchReleaseStatus();
      handleCloseModal();
      setPageToast({
        message: 'Approval email sent!',
        variant: 'success',
        isOpen: true
      });
    } catch (error) {
      setPageToast({
        message: `${(error as any)?.response?.data?.Detail ?? 'Error sending approval email'}`,
        variant: 'error',
        isOpen: true
      });
    }
  };

  const [isClientApproveModalOpen, setIsClientApproveModalOpen] = useState(false);
  const handleOpenClientApproveEmailModal = () => {
    setIsClientApproveModalOpen(true);
  };
  const handleCloseModal = async () => {
    setIsClientApproveModalOpen(false);
  };

  const isMediumDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <section className={classes.wrapper}>
        <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
          <Grid item xs={12}>
            <DashboardCard
              setHeight={false}
              isColumn={false}
              hasChildren={false}
              title={'Software Release'}
              disableJustifyContentOnMobile={true}
              useCardWrapper={false}
            />
          </Grid>
        </Grid>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <DashboardCard
              setHeight={false}
              isColumn={false}
              hideTitle={true}
              elevation={6}
              cardPadding='0'
              parentCardMarginBottom={'.25rem'}
              mobileStyles={{ maxWidth: '100%', padding: '1rem' }}
            >
              <Box pt={1} pb={1}>
                <ProgressStepper
                  title='Release Progress'
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  hasPendingChangesDate={hasPendingChangesDate}
                />
              </Box>
            </DashboardCard>
          </Grid>
        </Grid>
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.GENERAL_INFO) && (
          <ValidationProvider>
            <GeneralReleaseForm
              setClientId={setClientId}
              setReleaseId={setReleaseId}
              setReleaseDate={setReleaseDate}
              setSelectedReleaseTime={setSelectedReleaseTime}
              setTimeZone={setTimeZone}
            />
            <AssignedEmployees releaseId={releaseId?.toString() || ''} setEmployeesAssigned={setEmployeesAssigned} />
            <ClientContacts releaseId={releaseId?.toString() || ''} clientId={clientId} setContactsAssigned={setContactsAssigned} />
          </ValidationProvider>
        )}
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.RELEASE_DETAILS) && (
          <>
            <ValidationProvider>
              <ReleaseContents
                canDeliveryLeadSave={canDeliveryLeadSave ?? false}
                handleDeliveryLeadApprove={handleDeliveryLeadApprove}
                handleClientApproved={handleClientApproved}
                refetchCanDeliveryLeadSave={refetchCanDeliveryLeadSave}
                contactsAssigned={contactsAssigned}
                employeesAssigned={employeesAssigned}
                releaseDate={releaseDate}
                selectedReleaseTime={selectedReleaseTime}
                releaseStatusName={releaseStatusName}
              />
              <ReleaseDetails refetchCanDeliveryLeadSave={refetchCanDeliveryLeadSave} />
            </ValidationProvider>
          </>
        )}
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.WAITING_FOR_RELEASE) && (
          <WaitingRelease
            timezone={String(timeZone)}
            releaseDate={releaseDate ? releaseDate.toString() : null}
            releaseStatusName={releaseStatusName}
          />
        )}
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.RELEASING) && (
          <Releasing
            releaseId={releaseId}
            initialReleaseDate={releaseDate}
            initialSelectedReleaseTime={selectedReleaseTime}
            initialTimeZone={timeZone}
            onReleaseDateChange={setReleaseDate}
            onSelectedReleaseTimeChange={setSelectedReleaseTime}
            onTimeZoneChange={setTimeZone}
            onPendingChangesChange={setHasPendingChangesDate}
          />
        )}
        {activeStep === stepDefinition.findIndex(step => step.url === SoftwareReleaseUrlSubdirectory.VERIFYING) && (
          <Verifying releaseId={releaseId} />
        )}
      </section>
      <ReleaseApprovalModal
        isModalOpen={isClientApproveModalOpen}
        handleCloseModal={handleCloseModal}
        handleDeliveryLeadApproveSendEmail={handleDeliveryLeadApproveSendEmail}
        handleClientApprovedSendEmail={handleClientApprovedSendEmail}
        isMediumDown={isMediumDown}
        clientId={clientId}
        approvalType={approvalType}
      />
      <Toast
        id='page-toast'
        message={PageMessage}
        open={pageToastIsOpen}
        onClose={() =>
          setPageToast({
            message: '',
            variant: pageVariant,
            isOpen: false
          })
        }
        variant={pageVariant}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  cardHolder: {
    alignItems: 'stretch'
  }
}));

export default SoftwareReleaseDetail;
